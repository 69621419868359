// export default {

let ZipCodes = [
    89001,
89002,
89003,
89004,
89005,
89007,
89008,
89010,
89011,
89012,
89013,
89014,
89015,
89017,
89018,
89019,
89020,
89021,
89022,
89025,
89026,
89027,
89029,
89030,
89031,
89032,
89039,
89040,
89042,
89043,
89044,
89045,
89046,
89047,
89048,
89049,
89052,
89054,
89060,
89061,
89074,
89081,
89084,
89085,
89086,
89101,
89102,
89103,
89104,
89106,
89107,
89108,
89109,
89110,
89113,
89115,
89117,
89118,
89119,
89120,
89121,
89122,
89123,
89124,
89128,
89129,
89130,
89131,
89134,
89135,
89138,
89139,
89141,
89142,
89143,
89144,
89145,
89146,
89147,
89148,
89149,
89156,
89161,
89166,
89169,
89178,
89179,
89183,
89191,
89301,
89310,
89311,
89314,
89316,
89317,
89318,
89319,
89402,
89403,
89404,
89405,
89406,
89408,
89409,
89410,
89411,
89412,
89413,
89414,
89415,
89418,
89419,
89420,
89421,
89422,
89423,
89424,
89425,
89426,
89427,
89428,
89429,
89430,
89431,
89433,
89434,
89436,
89438,
89439,
89440,
89441,
89442,
89444,
89445,
89446,
89447,
89448,
89449,
89450,
89451,
89460,
89501,
89502,
89503,
89506,
89508,
89509,
89510,
89511,
89512,
89519,
89521,
89523,
89701,
89702,
89703,
89704,
89705,
89706,
89801,
89815,
89820,
89821,
89822,
89823,
89825,
89826,
89828,
89830,
89831,
89832,
89833,
89834,
89835,
89883,
85003,
85004,
85006,
85007,
85008,
85009,
85012,
85013,
85014,
85015,
85016,
85017,
85018,
85019,
85020,
85021,
85022,
85023,
85024,
85027,
85028,
85029,
85031,
85032,
85033,
85034,
85035,
85037,
85040,
85041,
85042,
85043,
85044,
85045,
85048,
85050,
85051,
85053,
85054,
85083,
85085,
85086,
85087,
85118,
85119,
85120,
85121,
85122,
85123,
85128,
85131,
85132,
85135,
85137,
85138,
85139,
85140,
85141,
85142,
85143,
85145,
85147,
85172,
85173,
85192,
85193,
85194,
85201,
85202,
85203,
85204,
85205,
85206,
85207,
85208,
85209,
85210,
85212,
85213,
85215,
85224,
85225,
85226,
85233,
85234,
85248,
85249,
85250,
85251,
85253,
85254,
85255,
85256,
85257,
85258,
85259,
85260,
85262,
85263,
85264,
85266,
85268,
85281,
85282,
85283,
85284,
85286,
85295,
85296,
85297,
85298,
85301,
85302,
85303,
85304,
85305,
85306,
85307,
85308,
85309,
85310,
85320,
85321,
85322,
85323,
85324,
85325,
85326,
85328,
85331,
85332,
85333,
85334,
85335,
85336,
85337,
85338,
85339,
85340,
85341,
85342,
85343,
85344,
85345,
85346,
85347,
85348,
85349,
85350,
85351,
85352,
85353,
85354,
85355,
85356,
85357,
85360,
85361,
85362,
85363,
85364,
85365,
85367,
85371,
85373,
85374,
85375,
85377,
85379,
85381,
85382,
85383,
85387,
85388,
85390,
85392,
85395,
85396,
85501,
85530,
85531,
85533,
85534,
85535,
85536,
85539,
85540,
85541,
85542,
85543,
85544,
85545,
85546,
85550,
85551,
85552,
85553,
85554,
85601,
85602,
85603,
85605,
85606,
85607,
85608,
85609,
85610,
85611,
85613,
85614,
85615,
85616,
85617,
85618,
85619,
85620,
85621,
85622,
85623,
85624,
85625,
85626,
85627,
85629,
85630,
85631,
85632,
85633,
85634,
85635,
85637,
85638,
85640,
85641,
85643,
85645,
85646,
85648,
85650,
85653,
85654,
85658,
85701,
85704,
85705,
85706,
85707,
85708,
85710,
85711,
85712,
85713,
85714,
85715,
85716,
85718,
85719,
85723,
85724,
85726,
85730,
85735,
85736,
85737,
85739,
85741,
85742,
85743,
85745,
85746,
85747,
85748,
85749,
85750,
85755,
85756,
85757,
85901,
85911,
85912,
85920,
85922,
85923,
85924,
85925,
85926,
85927,
85928,
85929,
85930,
85931,
85932,
85933,
85934,
85935,
85936,
85937,
85938,
85939,
85940,
85941,
85942,
86001,
86003,
86004,
86011,
86015,
86016,
86017,
86018,
86020,
86021,
86022,
86023,
86024,
86025,
86028,
86029,
86030,
86031,
86032,
86033,
86034,
86035,
86036,
86038,
86039,
86040,
86042,
86043,
86044,
86045,
86046,
86047,
86052,
86053,
86054,
86301,
86303,
86305,
86313,
86314,
86315,
86320,
86321,
86322,
86323,
86324,
86325,
86326,
86327,
86329,
86331,
86332,
86333,
86334,
86335,
86336,
86337,
86338,
86343,
86351,
86401,
86403,
86404,
86406,
86409,
86411,
86413,
86426,
86429,
86431,
86432,
86433,
86434,
86435,
86436,
86437,
86438,
86440,
86441,
86442,
86443,
86444,
86445,
86502,
86503,
86504,
86505,
86506,
86507,
86508,
86510,
86511,
86512,
86514,
86515,
86520,
86535,
86538,
86540,
86544,
86545,
86547,
86556,
80701,
80723,
80654,
80649,
80653,
80741,
80742,
80750,
80733,
80705,
80751,
80731,
80728,
80741,
80736,
80747,
80722,
80726,
80745,
80737,
80749,
80744,
80721,
80734,
80731,
80721,
80746,
80720,
80743,
80818,
80722,
80733,
80757,
80812,
80740,
80801,
80759,
80758,
80734,
80727,
80735,
80822,
80824,
80755,
80138,
80107,
80102,
80808,
80106,
80117,
80828,
80105,
80832,
80101,
80835,
80830,
81063,
80828,
80833,
80821,
80818,
81045,
80823,
80804,
80862,
81021,
80826,
80807,
80836,
80861,
80815,
80805,
80834,
81038,
80825,
80802,
80810,
80862,
81063,
81062,
81033,
81058,
81076,
81034,
81050,
81067,
81039,
81077,
81030,
81059,
81058,
81036,
81045,
81071,
81021,
81052,
81054,
81092,
81044,
81057,
81052,
81047,
81092,
81041,
81043,
81084,
81073,
81090,
81029,
81064,
81084,
81087,
80817,
81005,
81007,
81001,
81004,
81003,
81008,
81006,
81069,
81039,
81019,
81025,
81022,
81023,
81002,
81009,
81011,
81010,
81012,
81082,
81054,
81089,
81091,
81020,
81059,
81024,
81081,
81064,
81049,
81027,
81046,
81089,
81069,
81055,
81040,
81131,
96101,
96134,
96056,
96104,
96108,
96116,
96115,
96112,
96006,
96015,
96110,
96054,
96130,
96117,
96114,
96137,
96109,
96020,
96128,
96136,
95947,
96056,
96113,
96105,
96009,
96121,
96116,
96006,
96123,
96132,
96068,
96119,
96127,
95971,
96122,
96114,
95942,
96137,
96109,
95916,
96020,
96103,
96135,
95947,
96061,
96105,
95983,
95956,
95930,
95981,
96121,
96129,
95915,
96106,
95923,
95934,
95980,
95984,
96161,
96118,
95960,
96105,
95922,
95930,
95981,
96126,
96125,
95936,
96106,
96124,
95910,
95944,
93514,
93546,
93512,
93517,
96107,
96133,
93529,
93541,
93514,
93513,
93527,
93545,
92384,
92328,
93549,
92389,
93592,
93526,
93522,
93530,
93515,
93542,
93307,
93536,
93306,
93309,
93313,
93215,
93312,
93308,
93311,
93304,
93305,
93314,
93555,
93561,
93280,
93560,
93263,
93203,
93268,
93250,
93301,
93241,
93505,
93501,
93240,
93225,
93381,
93523,
93461,
93516,
93519,
93206,
93252,
93243,
93283,
93285,
93527,
93249,
93205,
93524,
93518,
93238,
93251,
93531,
93255,
93382,
93224,
93554,
93287,
93226,
93528,
93220,
93222,
93276,
93216,
93303,
93302,
93380,
93383,
93385,
93384,
93387,
93386,
93389,
93388,
93390,
93502,
93504,
93556,
93581,
93596,
92335,
92336,
92345,
92376,
91710,
91709,
91730,
92407,
92404,
92392,
91761,
92880,
91762,
92346,
92324,
91764,
91786,
92399,
92374,
92395,
92410,
92308,
92307,
91701,
91739,
91763,
92337,
92394,
92301,
92311,
92373,
93555,
92405,
92316,
92411,
92371,
91784,
92284,
91737,
92377,
92277,
92354,
92887,
92344,
92313,
92408,
91708,
92278,
92314,
92252,
92359,
92310,
92356,
91798,
92325,
92342,
92315,
92372,
92352,
92369,
92363,
92256,
92382,
93516,
92285,
92391,
92397,
92415,
91743,
92365,
92386,
92322,
92401,
92242,
92305,
92418,
92347,
93562,
92350,
92268,
92385,
92368,
92321,
92327,
92317,
92333,
91759,
92358,
92341,
92309,
92332,
92339,
92398,
92378,
92364,
92318,
92280,
92304,
92326,
92412,
92414,
92424,
92267,
92338,
93528,
93558,
92286,
92312,
92323,
92329,
92334,
92340,
92357,
92366,
92375,
92393,
92402,
92403,
92406,
92413,
92423,
92427,
91729,
91758,
91785,
92331,
92503,
92592,
92509,
92553,
92563,
92882,
92201,
92562,
92571,
92530,
92507,
92880,
92570,
92324,
92584,
92504,
92223,
92544,
92557,
92505,
92879,
92234,
92555,
92506,
92236,
92545,
92596,
92591,
92240,
92583,
92253,
92508,
92543,
92551,
91752,
92595,
92373,
92203,
92220,
92881,
92883,
92260,
92262,
92860,
92211,
92585,
92532,
92225,
92501,
92587,
92586,
92264,
92878,
92582,
92270,
92274,
92567,
92320,
92241,
92276,
92548,
92254,
92590,
92210,
92539,
92536,
92257,
92561,
92282,
92230,
92258,
92549,
92518,
92239,
92292,
92515,
92521,
92202,
92226,
92235,
92247,
92255,
92261,
92263,
92502,
92513,
92514,
92517,
92516,
92519,
92522,
92531,
92546,
92552,
92554,
92556,
92564,
92572,
92581,
92589,
92593,
92599,
92877,
92243,
92231,
92227,
92251,
92274,
92250,
92249,
92233,
92259,
92004,
92257,
92283,
92275,
92281,
91934,
92273,
92266,
92222,
92232,
92244,
84404,
84302,
84337,
84340,
84312,
84307,
84325,
84331,
84306,
84316,
84324,
84314,
84311,
84309,
84313,
84336,
84330,
84301,
84329,
84334,
84074,
84029,
84071,
84080,
84083,
84022,
84034,
84069,
84648,
84624,
84645,
84628,
84649,
84080,
84639,
84034,
84624,
84631,
84751,
84638,
84640,
84637,
84635,
84649,
84656,
84636,
84644,
84728,
84713,
84751,
84752,
84731,
84721,
84720,
84761,
84759,
84756,
84714,
84760,
84719,
84742,
84753,
84762,
84772,
84790,
84770,
84780,
84720,
84737,
84738,
84765,
84745,
84783,
84774,
84781,
84784,
84779,
84733,
84757,
84746,
84782,
84725,
84767,
84722,
84758,
84742,
84763,
84771,
84791,
84741,
84729,
84758,
84736,
84710,
84762,
84755,
84759,
84776,
84712,
84726,
84735,
84533,
84764,
84716,
84773,
84736,
84717,
84718,
84532,
84511,
84535,
84534,
84536,
84531,
84512,
84533,
84510,
84530,
84747,
84734,
84775,
84749,
84773,
84732,
84715,
84532,
84540,
84525,
84515,
84528,
84513,
84523,
84537,
84522,
84525,
84521,
84518,
84516,
84501,
84526,
84528,
84527,
84539,
84520,
84542,
84529,
84078,
84066,
84052,
84076,
84085,
84035,
84039,
84023,
84053,
84008,
84026,
84063,
84079,
84066,
84021,
84052,
84073,
84001,
84007,
84051,
84002,
84053,
84072,
84031,
84027,
87401,
87410,
87413,
87402,
87313,
87417,
87328,
87325,
87421,
87461,
87415,
87455,
87416,
87037,
87412,
87419,
87420,
87499,
87364,
87418,
87121,
87124,
87144,
87122,
87544,
87004,
87048,
87013,
87047,
87043,
87001,
87024,
87025,
87083,
87041,
87052,
87053,
87072,
87027,
87046,
87044,
87018,
87174,
87301,
87327,
87013,
87313,
87311,
86515,
87328,
87325,
87326,
87323,
87045,
87316,
87375,
87321,
87319,
87310,
87312,
87322,
87365,
87320,
87305,
87317,
87347,
87302,
87020,
87327,
87014,
87026,
87034,
87323,
87357,
87321,
87038,
87021,
87315,
87007,
87040,
87005,
87049,
87051,
87121,
87114,
87120,
87111,
87105,
87123,
87112,
87109,
87110,
87108,
87107,
87106,
87102,
87122,
87113,
87015,
87104,
87059,
87068,
87047,
87116,
87026,
87008,
87119,
87117,
87115,
87165,
87022,
87184,
87101,
87103,
87125,
87131,
87153,
87151,
87158,
87154,
87176,
87181,
87187,
87185,
87191,
87190,
87193,
87192,
87195,
87194,
87197,
87196,
87199,
87198,
87031,
87002,
87068,
87042,
87026,
87060,
87023,
87022,
87015,
87035,
87016,
87036,
88321,
87009,
87070,
88318,
87061,
87063,
87032,
87507,
87505,
87532,
87508,
87506,
87015,
87544,
87501,
87547,
87522,
87047,
87552,
87540,
87010,
87056,
87535,
87567,
87503,
87502,
87504,
87509,
87574,
87592,
87594,
87701,
87508,
88401,
87552,
87560,
88426,
87731,
87745,
87070,
87711,
87538,
87569,
87573,
87742,
87565,
88431,
87724,
87750,
87753,
88416,
87746,
87562,
88421,
88439,
87583,
87522,
87722,
87745,
87732,
87713,
87752,
87723,
87573,
87715,
87735,
87734,
87730,
87729,
87736,
87712,
87750,
87753,
87740,
87747,
87710,
87718,
87714,
87728,
88422,
87730,
87729,
87749,
88414,
88415,
88424,
88418,
88419,
88430,
88422,
88410,
88437,
88436,
88414,
88415,
88426,
88430,
88410,
87730,
87743,
87733,
87746,
88401,
88426,
88124,
88120,
88112,
88430,
88411,
88121,
88427,
88434,
88433,
88435,
88417,
87711,
88353,
88431,
87724,
87825,
87829,
87943,
88039,
87821,
87830,
87820,
88051,
87827,
87824,
87002,
87801,
87825,
87036,
87062,
87832,
87006,
87823,
87939,
87011,
87828,
87831,
87028,
88203,
88345,
88346,
88312,
88316,
88343,
88351,
88301,
88323,
88318,
88136,
88341,
88338,
88336,
88324,
88348,
88355,
88119,
88134,
88136,
88121,
88203,
88201,
88230,
88232,
88116,
88250,
88253,
88344,
88264,
88213,
88122,
88202,
88130,
88135,
88124,
88123,
88126,
88116,
88118,
88134,
88125,
88132,
88113,
88122,
88115,
88101,
88135,
88103,
88124,
88120,
88112,
88133,
88102,
88045,
88020,
88040,
88009,
88056,
88061,
88045,
88023,
88049,
88026,
88022,
88041,
88043,
88036,
88040,
88038,
88055,
88051,
88034,
88025,
88028,
88009,
88053,
88065,
88062,
88030,
88029,
88031,
88001,
88011,
88012,
88005,
88007,
88021,
88081,
88063,
88008,
88072,
87937,
88044,
88048,
88047,
88033,
88003,
88027,
87941,
88046,
88052,
87936,
88024,
87940,
88032,
88002,
88004,
88006,
88054,
88058,
88013,
87901,
87935,
87943,
88042,
87936,
87942,
87939,
87930,
87931,
87933,
87654,
79938,
88310,
88081,
88330,
88340,
88337,
88352,
88317,
88311,
88342,
79847,
88339,
88314,
88349,
88325,
88344,
88347,
88354,
88350,
88220,
88210,
88255,
88256,
88254,
88268,
88263,
88250,
88253,
88264,
88211,
88221,
88240,
88260,
88242,
88231,
88252,
88267,
88265,
88114,
88262,
88264,
88213,
88241,
88244,
79936,
79938,
79928,
79912,
79924,
79907,
79927,
79925,
79915,
79904,
79932,
79930,
79934,
79905,
79902,
79935,
79903,
79849,
79835,
79911,
79901,
79922,
79836,
79821,
79918,
79838,
79982,
79984,
79983,
79987,
79992,
79991,
79994,
79993,
79916,
79988,
79989,
79985,
79975,
79966,
79974,
79973,
79977,
79906,
79853,
79908,
79986,
79968,
88516,
79990,
79910,
79914,
79913,
79917,
79920,
79923,
79926,
79931,
79937,
79940,
79942,
79941,
79944,
79943,
79946,
79945,
79948,
79947,
79950,
79949,
79952,
79951,
79954,
79953,
79958,
79955,
79961,
79960,
79976,
79978,
79980,
79996,
79995,
79998,
79997,
79999,
88511,
88510,
88513,
88512,
88515,
88514,
88517,
88519,
88518,
88521,
88520,
88524,
88523,
88526,
88525,
88528,
88527,
88530,
88529,
88532,
88531,
88534,
88533,
88536,
88535,
88539,
88538,
88541,
88540,
88543,
88542,
88545,
88544,
88547,
88546,
88549,
88548,
88553,
88550,
88555,
88554,
88557,
88556,
88559,
88558,
88561,
88560,
88563,
88562,
88566,
88565,
88568,
88567,
88570,
88569,
88572,
88571,
88574,
88573,
88576,
88575,
88578,
88577,
88580,
88579,
88582,
88581,
88584,
88583,
88586,
88585,
88588,
88587,
88590,
88589,
88595,
79929,
79938,
79855,
79839,
79847,
79851,
79837,
79855,
79847,
79770,
79734,
79854,
79845,
79843,
79846,
79830,
79834,
79852,
79832,
79842,
79831,
79735,
79752,
79744,
79740,
79781,
79743,
79848,
79730,
79772,
79718,
79785,
79770,
79780,
79786,
79754,
79756,
79745,
79759,
79741,
79789,
79772,
79756,
79788,
79742,
79777,
79719,
78851,
79781,
79848,
79766,
79731,
79766,
79731,
79752,
79755,
79778,
79762,
79763,
79761,
79764,
79765,
79766,
79758,
79759,
79741,
79760,
79769,
79768,
79776,
79705,
79707,
79701,
79706,
79703,
79765,
79766,
79712,
79711,
79702,
79704,
79710,
79708,
79705,
79707,
79714,
79331,
79782,
79783,
79749,
79713,
79748,
79714,
79758,
79741,
79360,
79323,
79359,
79342,
79377,
79331,
79351,
79713,
79377,
79323,
79359,
79355,
79376,
79316,
79359,
79378,
79376,
79345,
79377,
79423,
79364,
79373,
79351,
79381,
79383,
79424,
79423,
79416,
79413,
79407,
79414,
79415,
79403,
79412,
79410,
79364,
79404,
79382,
79401,
79411,
79363,
79409,
79311,
79329,
79406,
79402,
79408,
79350,
79250,
79430,
79343,
79405,
79366,
79452,
79457,
79453,
79490,
79464,
79493,
79491,
79499,
79407,
79336,
79339,
79363,
79358,
79313,
79367,
79372,
79353,
79338,
79380,
79336,
79346,
79314,
79379,
79353,
79347,
79371,
79344,
79320,
79324,
79339,
79371,
79064,
79313,
79043,
79031,
79312,
79082,
79326,
79324,
79369,
79072,
79311,
79041,
79064,
79250,
79032,
79021,
79073,
79088,
79042,
79052,
79094,
79045,
79027,
79042,
79043,
79063,
79085,
79347,
79035,
79325,
79009,
79053,
79045,
79035,
79025,
79092,
79098,
79001,
79109,
79118,
79015,
79110,
79119,
79103,
79121,
79091,
79042,
79171,
79098,
79016,
79114,
40031,
40014,
40059,
40026,
40068,
40056,
40055,
40077,
40010,
40032,
79109,
79107,
79106,
79108,
79124,
79103,
79102,
79104,
79121,
79036,
79163,
79167,
79182,
79101,
79123,
79111,
79058,
79010,
79116,
79187,
79012,
79105,
79117,
79120,
79159,
79166,
79168,
79172,
79178,
79185,
79189,
28326,
28327,
28374,
28387,
28315,
27376,
27325,
28394,
27209,
27341,
27356,
27281,
28373,
27242,
28350,
27259,
28370,
28388,
79022,
79018,
79044,
79029,
79036,
79083,
79013,
79018,
79058,
79086,
79040,
79013,
79084,
73960,
79022,
79087,
79051,
73933,
73949,
73947,
73946,
73937

]


export default ZipCodes
// }